<template>
  <v-row class="fill-height">
    <v-col>
      <v-row>
        <v-col md="12">
          <v-card>
            <v-card-title>
              <v-icon
                @click="pushRouteToView('benefits')"
                class="mr-4"
                style="cursor: pointer"
                title="voltar"
              >
                mdi-arrow-left
              </v-icon>
              <v-icon class="mr-2">mdi-handshake</v-icon>
              Editar benefícios
            </v-card-title>
          </v-card>
        </v-col>

        <v-col sm="12" class="text-center">
          <v-row justify="center">
            <v-col md="6">
              <v-form
                v-model="benefitFormValidation"
                ref="benefitFormValidation"
                @submit.prevent="setBenefit()"
              >
                <v-row justify="center">
                  <!-- <v-col
                    v-if="$router.currentRoute.name === 'benefitAdd'"
                    lg="4"
                    md="4"
                    sm="12"
                  >
                    <v-text-field
                      :rules="textRules"
                      outlined
                      v-model="desc"
                      label="Descrição"
                    />
                  </v-col> -->

                  <v-col lg="4" md="4" sm="12">
                    <v-text-field outlined v-model="desc" label="Descrição" />
                  </v-col>

                  <v-col lg="4" md="4" sm="12">
                    <v-checkbox
                      v-model="allEmployee"
                      label="Todos os funcionários"
                    />
                  </v-col>

                  <v-col sm="10" class="text-right">
                    <v-btn
                      type="submit"
                      style="color: #fff; background-color: rgb(61, 17, 89)"
                    >
                      <v-icon left>
                        mdi-check
                      </v-icon>
                      Enviar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "categoriesEdit",

  data: () => ({
    benefitFormValidation: false,

    desc: "",
    allEmployee: false,
    categories: [],

    textRules: [
      v => !!v || "Este campo é obrigatório"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ]
  }),

  methods: {
    pushRouteToView(route) {
      this.$router.push({ name: route });
    },
    setBenefit() {
      console.log("set beneficio");
    }
  }
};
</script>
